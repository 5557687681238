

import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

export default function Cubes(props) {
    const {matCube, matCubeBevelBloom, matEmissionBottomCube } = props


    const group = useRef();
    const { nodes, materials, animations } = useGLTF("./model/cubes_joined_2.glb");
    const { actions } = useAnimations(animations, group);


    useEffect(() => {
        actions.Cube1.play().setEffectiveTimeScale(0.04);
      }, []);


    return (
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group name="Armature" position={[-2.357, 0, -8.127]}>
            <group name="Cubes_groupe">
              <skinnedMesh
                name="Cube007"
                geometry={nodes.Cube007.geometry}
                material={matCube}
                skeleton={nodes.Cube007.skeleton}
              />
              <skinnedMesh
                name="Cube007_1"
                geometry={nodes.Cube007_1.geometry}
                material={matCubeBevelBloom}
                skeleton={nodes.Cube007_1.skeleton}
              />
              <skinnedMesh
                name="Cube007_2"
                geometry={nodes.Cube007_2.geometry}
                material={matEmissionBottomCube}
                skeleton={nodes.Cube007_2.skeleton}
              />
            </group>
            <primitive object={nodes.Root} />
          </group>
        </group>
      </group>
  );
}

useGLTF.preload("./model/cubes_joined_2.glb");