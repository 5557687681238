import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import Cubes from './Cubes'


export default function Models(){

    // Color if no buildings "#5d84d7"
    const Cube = new THREE.MeshStandardMaterial({
        color: "#4565A8",
        metalness: 0.8,
        roughness: 0.15,
        toneMapped: false     
    })

    const CubeBevelBloom = new THREE.MeshStandardMaterial({
        emissive: '#ff90ff',
        emissiveIntensity: 3,
        toneMapped: false       
    })

    const EmissionBottomCube = new THREE.MeshStandardMaterial({
        emissive: '#ff59fd',
        emissiveIntensity: 2,
        toneMapped: false
    })

    const EmissionOrange = new THREE.MeshBasicMaterial({
        color: new THREE.Color(3, 1, 1),
        emissiveIntensity: 2,
        toneMapped: false
    })

    const DarkGrey = new THREE.MeshStandardMaterial({
        color: '#7a7a7a',
        metalness: 1,
        roughness: 0.5
    })


    //Floor
    const floor2 = useLoader(GLTFLoader, './model/floor2.glb')

    console.log(floor2);



    const floorMetalMap = useLoader(TextureLoader, './textures/floor/Metal031_1K_Metalness.png')
    const floorNormalMap = useLoader(TextureLoader, './textures/floor/Metal031_1K_NormalGL.png')
    const floorRoughnessMap = useLoader(TextureLoader, './textures/floor/Metal031_1K_Roughness.png')

    return <>

    {/* Cubes */}
    <group position={[-1,0,-2]}>
        <Cubes matCube={Cube} matCubeBevelBloom={CubeBevelBloom} matEmissionBottomCube={EmissionBottomCube} />  
    </group>
    
    {/* Floor */}
    <mesh position={[0,0,0]} geometry={floor2.nodes.Floor.geometry}>
    <meshStandardMaterial color={'#232959'} metalnessMap={floorMetalMap} metalness={1} normalMap={floorNormalMap} roughnessMap={floorRoughnessMap} />
    </mesh>
 
    <mesh position={[0,0,0]} geometry={floor2.nodes.CubeBevelBloom.geometry} material={CubeBevelBloom}>
    </mesh>
  
    <mesh position={[1,0,0]} geometry={floor2.nodes.DarkGrey.geometry} material={DarkGrey}>
    </mesh>

    <mesh  geometry={floor2.nodes.EmissionOrange.geometry} material={EmissionOrange}>
    </mesh>

    <mesh  geometry={floor2.nodes.EmissionWhite.geometry} material={EmissionOrange}>
    </mesh>


    </>
}