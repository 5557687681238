import Companies from './components/companies/Companies.jsx';
import Projects from './components/projects/Projects.jsx';
import Metaverse from './components/metaverse/Metaverse.jsx';
import Cryptocurrency from './components/cryptocurrency/Cryptocurrency.jsx';
import Contact from './components/contact/Contact.jsx';
import Footer from './components/footer/Footer.jsx';

export default function Experience2() {

    return (
        <>
        <div className="page-header">
        {/* Cubes */}
                <div className="squares square1" />
                <div className="squares square2" />
                <div className="squares square3" />
                <div className="squares square4" />
                <div className="squares square5" />
                <div className="squares square6" />
                <div className="squares square7" />
        {/* About Me */}
            <div className="rows"> 

                <div className="col-left">

                    <div className="about-me">
                        <div id="about-me-tag" className="title">About Me</div>
                        <div className="about-me-content"> 
                            <div className="about-me-text">
                            Welcome to my digital space! With a Master’s degree in Fundamental Physics and a deep passion for exploring complex systems, I navigate both scientific and computer science domains with equal enthusiasm. My RNCP Level 1 certification in Computer Analysis and expertise in JAVA/JEE network architecture empower me to create efficient and innovative web solutions.
                                <br/>
                                <br/>
                                Driven by curiosity and creativity, I push the boundaries of innovation, leveraging coding skills to bring ambitious projects to life. Let's explore together how we can turn possibilities into realities through innovative solutions.                           </div>
                        </div>

                        <div className="about-me-image">
                            <img className="about-me-image-img" src="./../img/james.jpg"></img>  
                        </div>  
                    </div>
                    
                </div>

                <div className="col-right">

                    <div className="technologies">
                        <div className="technologies-card">
                            <div className="card-content">
                                <div className="title">Technologies</div>
                                <p>
                                As a developer, I leverage the latest tools and techniques to create cutting-edge web solutions. My commitment to continuous learning keeps me at the forefront of technology, allowing me to deliver innovative and impactful projects. That's why I don't use Angular.
                                </p>
                                <hr style={{width: "80%"}}/>
                                <div className="title-small">
                                    Front-End
                                </div>
                                <div className="technologies-list">
                                    <div>React</div>
                                    <div>ThreeJS</div>
                                    <div>GLSL</div>
                                </div>
                                <div className="title-small">
                                    Back-End
                                </div>
                                <div className="technologies-list">
                                    <div>JAVA</div>
                                    <div>SPRING</div>
                                    <div>Servlet API</div>
                                    <div>JSF</div>
                                </div>
                                <div className="title-small">
                                    Devops
                                </div>
                                <div className="technologies-list">
                                    <div>AWS</div>
                                    <div>Docker</div>
                                    <div>Github</div>
                                    <div>BitBucket</div>
                                    <div>Jenkins</div>
                                </div>
                                <div className="title-small">
                                    Cybersecurity
                                </div>
                                <div className="technologies-list">
                                    <div>Secure Coding</div>
                                    <div>Risk Assessment</div>
                                    <div>OSINT</div>
                                    <div>Google Dorcking</div>
                                </div>
                                <div className="title-small">
                                    Metaverse
                                </div>
                                <div className="technologies-list">
                                    <div>C#</div>
                                    <div>Unity3D</div>
                                    <div>Blender</div>
                                    <div>Krita</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        {/* Companies */}
            <Companies/>
        {/* Projects */}
            <Projects/>      
        {/* Metaverse */}
            <Metaverse/>
        {/* CryptoCurrency */}
            <Cryptocurrency/>
        {/* Contact */}
        <hr style={{width: '80%'}}/>
            <Contact/>
        {/* Footer */}
            <Footer/>
         </div>     
        </>   
      );
}