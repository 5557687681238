import './projects.css'
import React, { Component } from 'react';
import Websites from './../websites/Websites.jsx';
import Slider from "react-slick";

const tabs = [
    {
        id: 1,
        tabTitle: '1',
        title: 'Spatio-temporal analyzes of light variability in Australian and Canadian coastal waters',
        content: 'During my final bachelor\'s internship with the LOG, I worked on leveraging a satellite database to study the ocean\'s optical and physical parameters. This involved developing a specialized program for detailed analyses of specific oceanic regions. The internship enhanced my understanding of remote sensing and oceanography while strengthening my programming and data analysis skills. It was a valuable experience that contributed to my professional growth in the field of Earth sciences.',
        contentShort:'During my bachelor\'s internship, I used satellite data to study ocean properties. I created a specialized program for detailed analysis of certain areas, boosting my remote sensing, programming, and data skills, and enriching my Earth sciences career.',
        img: './../img/plume2.jpg'
    },
    {
        id: 2,
        tabTitle: '2',
        title: 'Evaluation of high spatial resolution image processing algorithms for the European Space Agency',
        content: 'During my master\'s program, I completed an internship with the LOG involving high spatial resolution image processing using various algorithms. The project also entailed analyzing satellite measurements and comparing them with data from a terrestrial network. This experience significantly bolstered my skills in image processing, algorithm implementation, and advanced data analysis techniques, particularly in the domain of remote sensing and geospatial analysis.',
        contentShort:'In my master\'s program, I interned with LOG, focusing on high-resolution image processing and satellite data analysis. This enhanced my expertise in image processing, algorithms, and advanced data analysis, particularly in remote sensing and geospatial analysis.',
        img: './../img/sentinel2_2.jpg'
    },
    {
        id: 3,
        tabTitle: '3',
        title: 'Modeling the beam line of the ARRONAX cyclotron-type particle accelerator.',
        content: 'During my master\'s internship, I performed Monte-Carlo type simulations of a cyclotron-produced particle beam. With precision and dedication, I meticulously recreated the intricate interplay of particles, energies, and forces that characterize this fascinating process. The goal was to compare these simulations with experimental results, contributing to the advancement of scientific research in particle physics and computational modeling. Beyond the lines of code and algorithms, I discovered the profound impact of collaboration between theory and experiment.',
        contentShort:'During my master\'s internship, I performed Monte-Carlo type simulations of a cyclotron-produced particle beam. The goal was to compare these simulations with experimental results, contributing to the advancement of scientific research in particle physics and computational modeling.',
        img: './../img/gate.jpg'
    },
    {
        id: 4,
        tabTitle: '4',
        title: 'IBM/BMW: Enhancing Global Dealership Application',
        content: 'During my time at IBM from 2021 to 2023, I actively participated in a significant BMW project, contributing to the maintenance and enhancement of an internal application used by BMW dealers worldwide. Contributing into a complex and high-impact project provided me with an exceptionally enriching journey, where I gained not only technical proficiencies but also a profound understanding of the dynamics of enterprise-level development. This experience has undoubtedly shaped my professional journey and continues to influence my approach to creating meaningful and impactful technological solutions.',
        contentShort:'During my time at IBM from 2021 to 2023, I actively participated in a significant BMW project, contributing to the maintenance and enhancement of an internal application used by BMW dealers worldwide. This experience proved immensely enriching, equipping me with invaluable insights and expertise in enterprise-level development.',
        img: './../img/ibm_bmw.jpg'
    }
];

export default function Projects() {

    return (

        <div className="projects-rows">
            <div className="projects-rows-in">

                <div className="projects-title">My Work & Projects</div>

                <div className="projects-card">
                    <SimpleSlider/>            
                </div>
                
                <Websites/>

            </div>             
        </div>
      );
}

class SimpleSlider extends Component {
    render() {
      const settings = {
        customPaging: function(i) {
            return (
              <div style={{padding: '10px 22px'}}>
                {i + 1}
              </div>
            );
          },
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        };
      return (
        <div>
          <Slider {...settings}>
            <div>
                <div className="projects-card-content">
                    <div className="projects-card-text">
                        <div className="projects-card-title">{tabs[0].title}</div>
                        <div className='projects-card-text-content'>
                        {tabs[0].content}
                        </div>
                        <div className='projects-card-text-content-short'>
                        {tabs[0].contentShort}
                        </div>
                    </div>
                    <div className="projects-card-image">
                        <img className="projects-card-image-img" src={tabs[0].img}></img>   
                    </div>
                </div>
            </div>
            <div>
                <div className="projects-card-content">
                    <div className="projects-card-text">
                        <div className="projects-card-title">{tabs[1].title}</div>
                        <div className='projects-card-text-content'>
                        {tabs[1].content}
                        </div>
                        <div className='projects-card-text-content-short'>
                        {tabs[1].contentShort}
                        </div>
                    </div>
                    <div className="projects-card-image">
                        <img className="projects-card-image-img" src={tabs[1].img}></img>   
                    </div>
                </div>
            </div>
            <div>
                <div className="projects-card-content">
                    <div className="projects-card-text">
                        <div className="projects-card-title">{tabs[2].title}</div>
                        <div className='projects-card-text-content'>
                        {tabs[2].content}
                        </div>
                        <div className='projects-card-text-content-short'>
                        {tabs[2].contentShort}
                        </div>
                    </div>
                    <div className="projects-card-image">
                        <img className="projects-card-image-img" src={tabs[2].img}></img>   
                    </div>
                </div>
            </div>
            <div>
                <div className="projects-card-content">
                    <div className="projects-card-text">
                        <div className="projects-card-title">{tabs[3].title}</div>
                        <div className='projects-card-text-content'>
                        {tabs[3].content}
                        </div>
                        <div className='projects-card-text-content-short'>
                        {tabs[3].contentShort}
                        </div>
                    </div>
                    <div className="projects-card-image">
                        <img className="projects-card-image-img" src={tabs[3].img}></img>   
                    </div>
                </div>
            </div> 
          </Slider>
          <hr/>
        </div>
      );
    }
  }