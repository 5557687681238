import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "three";

export default function Avatar(props) {
  
    const group = useRef();
    const avatarColorMap = useLoader(TextureLoader, './textures/avatar/BASE_COLOR_TEXTURE_ALPHA.png')

    const { nodes, animations } = useGLTF("./model/Avatar_v3.glb");
    const { ref, actions, names, mixer } = useAnimations(animations, group);


    const [index, setIndex] = useState(0);
    const [time, setTime] = useState(8);
   
  
    useEffect(() => {
      // Automatically change animation index every 10 seconds
      const interval = setInterval(() => {
     
        setIndex((prevIndex) => (prevIndex + 1) % names.length);

      }, time * 1000);
  
      // Clean up the interval when the component unmounts
      return () => {
        clearInterval(interval);
      };
    }, [names]);
  
    //Play animation when Index change
    useEffect(() => {

      setTime(animations[index].duration)
      // Reset and fade in animation after an index has been changed
      actions[names[index]].reset().fadeIn(0.5).play();
  
      // In the clean-up phase, fade it out
      return () => actions[names[index]].fadeOut(0.5);
    }, [actions, index, names]);
  

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" ref={ref} rotation={[0,Math.PI * 1.05,0]} scale={2} position={[0,0,-2.5]}>
          <group name="Body">
            <skinnedMesh
              name="Wolf3D_Avatar_Transparent001"
              geometry={nodes.Wolf3D_Avatar_Transparent001.geometry}
              skeleton={nodes.Wolf3D_Avatar_Transparent001.skeleton}
              morphTargetDictionary={
                nodes.Wolf3D_Avatar_Transparent001.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.Wolf3D_Avatar_Transparent001.morphTargetInfluences
              }
            >
                <meshStandardMaterial map={avatarColorMap}  map-flipY={false} envMapIntensity={1.5} />
            </skinnedMesh>
            <skinnedMesh
              name="Wolf3D_Avatar_Transparent001_1"
              geometry={nodes.Wolf3D_Avatar_Transparent001_1.geometry}
              material={nodes.Wolf3D_Avatar_Transparent001_1.material}
              skeleton={nodes.Wolf3D_Avatar_Transparent001_1.skeleton}
              morphTargetDictionary={
                nodes.Wolf3D_Avatar_Transparent001_1.morphTargetDictionary
              }
              morphTargetInfluences={
                nodes.Wolf3D_Avatar_Transparent001_1.morphTargetInfluences
              }
            />
          </group>
          <primitive object={nodes.Hips} />
          <primitive object={nodes.Ctrl_Master} />
          <primitive object={nodes.Ctrl_ArmPole_IK_Left} />
          <primitive object={nodes.Ctrl_Hand_IK_Left} />
          <primitive object={nodes.Ctrl_ArmPole_IK_Right} />
          <primitive object={nodes.Ctrl_Hand_IK_Right} />
          <primitive object={nodes.Ctrl_Foot_IK_Left} />
          <primitive object={nodes.Ctrl_LegPole_IK_Left} />
          <primitive object={nodes.Ctrl_Foot_IK_Right} />
          <primitive object={nodes.Ctrl_LegPole_IK_Right} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./model/Avatar_v3.glb");