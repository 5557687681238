import './companies.css'

export default function Companies() {

    return (
        <div className="companies-rows">
            <div className="companies-card">
                <div className="companies-title">Companies I've had worked</div>
                <div className="companies-logos">
                    <a href="https://www.bmw.fr/fr/accueil.html" target="_blank"><img style={{width: '95px'}} src="./../svg/bmw.svg"/></a>
                    <a href="https://www.ibm.com/fr-fr" target="_blank"><img style={{width: '175px'}} src="./../svg/ibm.svg"/></a>
                    <a href="https://www.arronax-nantes.fr/" target="_blank"><img style={{width: '190px'}} src="./../img/arronax.png"/></a>
                    <a href="https://log.cnrs.fr/" target="_blank"><img src="./../img/log.png"/></a>
                </div> 
            </div>
        </div>
      );
}