import { useEffect, useState } from 'react'
import { Environment, OrbitControls } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { easing } from 'maath'

import Models from './components/Models'
import Effects from './components/Effects'
import { Particles } from './components/Particles'

import Avatar from './components/Avatar'

export default function Experience()
{

    const [showCanvas, setShowCanvas] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY;
          // Define a threshold to determine when to hide the canvas
          setShowCanvas(scrollPosition < window.innerHeight * 0.99);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    // Animate the environment as well as the camera
    useFrame((state, delta) => {
        easing.damp3(state.camera.position, [4.5 + state.pointer.x * 0.5, 1.0, 5], 0.5, delta)  
      })

    return <>
        {/* <OrbitControls/>   */}

        {/* Postprocessing & Effects */}  
        <Environment
         files={['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg']}
         path='/hdri/firework/' />

        
        {/* Main scene */}  
        {showCanvas && (<>
        <Effects/>
        <Models/>
        <Particles />
        </>
      )}

       {/* Avatar */}
       <Avatar/>
        
        
        
    </>
}

