import { Point, Points } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import React from 'react'

const particleColors = ['#673ab7', '#f4b677', 'orange', 'blue', '#8bc34a', 'purple']

export function Particles({ size = 500}) {



  return (
    <Points limit={size}>
      <pointsMaterial size={0.05} vertexColors />
      {Array.from({ length: size }).map((_, i) => (
        <Point
          key={i}
          position={[
            ((Math.random()) * 100) - 70,
            ((Math.random()) * 25) - 2 ,
            (Math.random() * 20) - 30,
          ]}
          color={particleColors[Math.floor(Math.random() * (particleColors.length - 1))]}
        />
      ))}
    </Points>
  )
}
