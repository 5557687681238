import './footer.css'

export default function Footer() {

    return (
        <footer className="footer">
            <div className="footer-text">
                © 2023 Andrieux Loïc
            </div>
        </footer>
      );
}