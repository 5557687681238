import { Bloom, EffectComposer } from '@react-three/postprocessing'

export default function Effects(){

    return (
        <EffectComposer>
            <Bloom
                mipmapBlur
                intensity={0.8}
                luminanceThreshold={1}
            /> 
        </EffectComposer>
    )
}