import './cryptocurrency.css'

export default function Cryptocurrency() {

    return (
        <div className="crypto-rows">

            <div className="crypto-image">
                <img className="crypto-image-img" src="./../img/crypto.jpg"/>
            </div>   

            <div className="crypto-content">
                <div className="crypto-title">My Venture into the World of Cryptocurrency</div>
                <div className="crypto-text"> 
                    As an ex-cryptocurrency miner, I delved into the dynamic world of blockchain technology and decentralized networks. My experience in mining cryptocurrencies provided me with a unique perspective on the inner workings of digital assets and their underlying technologies. 
                </div>
            </div>  

        </div>
      );
}