import './style.css'
import './../public/css/slick.css'
import './../public/css/slick-theme.css'
import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { Canvas } from '@react-three/fiber'

import Experience from './Experience.jsx'
import Experience2 from './Experience2'
import Overlay from './components/Overlay'
import { Loader } from '@react-three/drei'
import { Perf } from 'r3f-perf'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <section className='section-1'>
            <Loader/>
            <Suspense fallback={null}>
                <Canvas 
                resize={{ scroll: false }}  
                camera={ {
                    fov: 60,
                    near: 0.1,
                    far: 200,
                    position: [5, 1, 5],
                    rotation: [0,0.5,0]  
                }}
                >       
                {/* <Perf position="top-left" /> */}
                    <Experience />
                </Canvas>
                <Overlay/>
            </Suspense>  
        </section>
        <section className='section-2'>
            <Experience2/>
        </section>
    </>
) 