import './metaverse.css'

export default function Metaverse() {

    return (
        <div className="metaverse-rows">

        <div className="metaverse-content">
            <div className="metaverse-title">Journey to the Metaverse</div>
            <div className="metaverse-text">                               
                In the ever-evolving metaverse, I proudly created and managed an immersive digital space that attracted more than 2.000.000 visits in 2024. Harnessing the power of web development, I continue to push boundaries and create exceptional virtual experiences that captivate audiences worldwide. Visit the French Vibes on <a className="metaverse-link" href="https://hello.vrchat.com/" target="_blank">VRChat</a>, <a className="metaverse-link" href="https://documentation.abinteractive.net/" target="_blank">ChilloutVR</a> and soon <a className="metaverse-link" href="https://recroom.com/" target="_blank">RecRoom</a>!
            </div>
        </div>

        <div className="metaverse-image">
            <img className="metaverse-image-img" src="./../img/metaverse.jpg"/>
        </div>   

    </div> 
      );
}