import './websites.css'

export default function Websites() {

    return (
        <div className="websites-row">
            <div className="websites-col">
                <div className="websites-title">
                    VR headset comparator 
                </div>
                <div>
                    <a href="https://all-about-vr.com/" target="_blank"><img className="websites-img" src="./../img/all-about-vr.jpg"/></a>
                </div>
            </div>
            <div className="websites-col">
                <div className="websites-title">
                    Documentation for a metaverse space
                </div>
                <div>
                    <a href="https://cvrmods.com/" target="_blank"><img className="websites-img" src="./../img/cvrmods.jpg"/></a>
                </div>
            </div>
        </div>
      );
}