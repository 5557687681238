import './contact.css'

export default function Contact() {

    return (
        <div className="contact">
            <div className="contact-title">
                Do you want to contact me?
            </div>
            <div className="contact-text">
                Feel free to reach out for any job opportunities or collaborations! <br/>
                You can contact me by email or on Linkedin.
            </div>
            <div className="contact-buttons">
                <div>
                    <a className="contact-button-mail" href="mailto:andrieuxlc@gmail.com">Mail</a>
                </div>
                <div>
                    <a className="contact-button-mail-blue" href="https://www.linkedin.com/in/loïc-andrieux" target="_blank">LinkedIn</a>
                </div>       
            </div>
        </div>
      );
}