export default function Overlay() {

    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB');
    
    return (
      <>
        <div className="text-top-right">
            <div style={{ position: 'absolute', top: 40, right: 160 , letterSpacing: 2}}>
                Andrieux
                <br />
                Loïc
            </div>

            <div style={{ position: 'absolute', top: 40, right: 40}}>
                —
                <br />
                {formattedDate}
            </div>
        </div>

        {/* <svg style={{ position: 'absolute', right: 40, top: '50%' }} width="54" height="23" viewBox="0 0 54 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="1.5" x2="54" y2="1.5" stroke="white" strokeWidth="3" />
          <line y1="11.5" x2="54" y2="11.5" stroke="white" strokeWidth="3" />
          <line y1="21.5" x2="54" y2="21.5" stroke="white" strokeWidth="3" />
        </svg> */}

        <div className="bottom">
            <div className="bottom-text" >
                <div className="bottom-text-info">
                    Exploring the Boundaries of 
                    <br />
                    Web Development with me.
                </div>
                <div className="bottom-text-link">                   
                        <a style={{ fontSize: '15px', fontWeight: 600, letterSpacing: 2 }} href="#about-me-tag">
                        LEARN MORE
                        </a>
                        <div style={{ marginTop: 6, height: 2.5, width: '100%', background: '#3e3e3d' }} />
                </div>             
            </div>
        </div>
      </>
    )
  }